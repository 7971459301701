<template lang="pug">
	.single-post
		.text
			h2 {{ post.name }}
			i {{ post.created_at }}
			div(v-html="post.content")
			
</template>

<script>
	export default {
		name: 'Home',
		data(){
			return{
				slug: this.$route.params.post,
				post: ''
			}
		},
		watch: {
			'$route.params.post'(newSlug) {
				this.slug = newSlug;
				this.getPost();
				// this.loadContent(newSlug);
				// console.log(newSlug)
			}
		},
		// watch: {
		// 	$route() {
		// 		console.log('sdfsd', this.slug)
		// 		// this.slug = to.params.page;
		// 		this.getPost();
		// 	}
		// },
		created: function () {
			this.getPost();
		},
		methods: {
			getPost(){
				window.api.get('get_page/' + this.slug + '/')
					.then(post => {
						this.post = post.data
					})
					.catch(postError => {
						console.log(postError);
					})
			}
		}
	}
</script>
