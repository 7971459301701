<template lang="pug">
	.center-layer(:style="{ backgroundImage: `url(${bg})` }")#app
		<Header/>

		.main
			.container
				.row
					.col-lg-12
						<router-view/>
					//- .col-lg-4
					//- 	<Sidebar/>

					//- .col-lg-8
					//- 	<router-view/>

		<Footer/>

</template>

<style lang="scss">
	@import "./mainStyles/main.scss";
</style>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import defaultBg from '@/assets/bg.jpg';

export default {
	name: 'App',
	components: {
		Header,
		Footer,
		Sidebar
	},
	data(){
		return{
			bg: null,
			defaultImage: defaultBg
		}
	},
	// api/get_settings/
	created: function () {
		this.getBG();
	},
		methods: {
			getBG(){
				window.api.get('get_settings/')
					.then(bg => {
						if(bg.data.background_image){
							this.bg = bg.data.background_image
						}else{
							this.bg = this.defaultImage
						}
					})
					.catch(bgError => {
						console.log(bgError)
						this.bg = this.defaultImage
					})
			}
		}

}
</script>
