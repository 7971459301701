<template lang="pug">
	.top
		header
			.container
				.row
					.col-12
						.inline
							a(href="/").logo
								h1 ДНЗ Калинка
						
							nav
								//- router-link(to="/", v-bind:class="{ active: '/' == $route.path}") Головна
								.single-item(v-for="item in menu")
									p(v-if="item.children.length") {{ item.name }}
									router-link(
										v-bind:class="{ active: item.slug == $route.params.page}",
										:to='{name: "Posts", params: { page: item.slug }}',
										v-else
									) {{ item.name }}
									.level(v-if="item.children.length")
										//- a(href="", v-for="level in item.children") {{ level.name }}
										//- router-link(:to='{name: "SinglePost", params: { post: item.slug }}')
										router-link(
											v-for="level in item.children"
											v-bind:class="{ active: level.slug == $route.params.page}",
											:to='{name: "SinglePost", params: { post: level.slug }}',
										) {{ level.name }}
								.single-item
									router-link(to="/photoalbums", v-bind:class="{ active: '/photoalbums' == $route.path}") Фотоальбоми
</template>

<script>
	export default {
		name: 'Header',
		data(){
			return{
				menu: []
			}
		},
		created: function () {
			window.api.get('get_menu/')
				.then(menu_items => {
					this.menu = menu_items.data
				})
				.catch(menuError => {
					console.log(menuError);
				})
		}
	}
</script>